import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "redux-little-router";
import { saveAs } from "file-saver";
import Spinner from "react-spinkit";

import BreadCrumbBar from "../../../components/BreadCrumbBar";
import "./BookOfBusiness.css";
import DateInputField from "../../../shared/components/DateInputField";
import config from "../../../config";

class BookOfBusiness extends React.Component {
  constructor(props) {
    super(props);
    this._onChangeStartDate = this._onChangeStartDate.bind(this);
    this._onChangeEndDate = this._onChangeEndDate.bind(this);
    this._triggerReport = this._triggerReport.bind(this);
    this._downloadZipReport = this._downloadZipReport.bind(this);
    this.state = {
      selectedSurvey: "satisfaction",
    };
  }

  componentDidMount() {
    const { requestCustomers } = this.props;
    requestCustomers();
    this.requestDropdown().catch(console.error);
  }

  async requestDropdown() {
    const response = await fetch(`${config.API_HOST}/api/admin/v1/assessments`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    this.setState({ assessmentList: data });
  }

  componentWillReceiveProps(nextProps) {
    const { selectedCustomer, updateStartDate, updateEndDate } = this.props;
    if (
      nextProps.selectedCustomer &&
      selectedCustomer !== nextProps.selectedCustomer
    ) {
      updateStartDate(moment().startOf("month"));
      updateEndDate(moment().endOf("month"));
    }
  }

  _dataURIToBlob(dataURI) {
    const { selectedCustomer } = this.props;
    const date = moment(new Date().getTime()).format("MM-DD-YYYY");

    const binaryString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const typedArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i += 1) {
      typedArray[i] = binaryString.charCodeAt(i);
    }

    const bb = new Blob([arrayBuffer], { type: "zip" });
    bb.name = `${selectedCustomer.name} Book Of Business ${date}`;
    return bb;
  }

  _onChangeStartDate(timestamp) {
    const { updateStartDate } = this.props;
    updateStartDate(timestamp);
  }

  _onChangeEndDate(timestamp) {
    const { updateEndDate } = this.props;
    updateEndDate(timestamp);
  }

  _triggerReport() {
    const {
      requestBookOfBusiness,
      resetReport,
      selectedCustomer,
      startDate,
      endDate,
    } = this.props;
    const { selectedSurvey } = this.state;
    resetReport(selectedCustomer.id);
    requestBookOfBusiness({
      customerId: selectedCustomer.id,
      startDate,
      endDate,
      selectedSurvey,
    });
  }

  _requestReportButton() {
    const { selectedCustomer } = this.props;
    if (selectedCustomer) {
      return (
        <div>
          <button
            className="btn btn-primary matrix-export-button"
            onClick={this._triggerReport}
          >
            Request new Book Of Business
          </button>
          &nbsp;&nbsp;Survey report:&nbsp;&nbsp;&nbsp;&nbsp;
          {this.dropdown()}
        </div>
      );
    }
    return null;
  }

  dropdown() {
    const assessmentList = this.state.assessmentList;
    if (!assessmentList) {
      return;
    }

    const allReports = [
      {
        label: "GHL Survey data export",
        value: "ghl",
      },
      {
        label: "Program engagement report",
        value: "engagement",
      },
      ...assessmentList
        .map((v) => {
          const isCombined = v.indexOf("combined") == 0;

          let label = `Single survey: ${v}`;

          if (isCombined) {
            label = `Combined survey: (${v
              .replace("combined:", "")
              .split("#")
              .join(", ")})`;
          }

          return {
            value: v,
            label,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];

    return (
      <select
        name="assessment"
        onChange={(v) => this.setState({ selectedSurvey: v.target.value })}
      >
        {allReports.map(({ label, value }) => (
          <option
            selected={value === this.state.selectedSurvey}
            key={value}
            value={value}
          >
            {label}
          </option>
        ))}
      </select>
    );
  }

  _downloadZipReport() {
    const { report, selectedCustomer } = this.props;
    saveAs(
      this._dataURIToBlob(report.payload),
      `${selectedCustomer.name} Book of Business.zip`
    );
  }

  _renderExportButton() {
    const { selectedCustomer, report, startDate, endDate } = this.props;

    if (report && report.status === "completed" && endDate > startDate) {
      return (
        <button
          className="btn btn-primary download-button"
          onClick={this._downloadZipReport}
        >
          Download {selectedCustomer.name} Book Of Business
        </button>
      );
    } else if (report && report.status === "pending") {
      return (
        <div
          style={{
            margin: "10px",
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p style={{ fontSize: "25px", marginRight: "10px" }}>
            Fetching Report
          </p>
          <Spinner name="circle" color="rgba(0,0,0,0.4)" />
        </div>
      );
    }
    return null;
  }

  renderDescription() {
    return (
      <div className="book-of-business-description">
        <h1>Book of Business</h1>
        <h2>
          Engagement/adoption/owner conversion rate by partner and customer for
          the purpose of sharing with leaders there
        </h2>
      </div>
    );
  }

  renderCalendars() {
    const { selectedCustomer, startDate, endDate } = this.props;

    const minEndDate = moment(startDate).add(1, "days");
    const maxStartDate = moment(endDate).subtract(1, "days");
    const maxEndDate = moment(startDate).add(6, "month");
    if (endDate > maxEndDate) {
      this._onChangeEndDate(maxEndDate);
    }

    if (selectedCustomer) {
      return (
        <div className="calendars">
          <div className="book-of-business-container">
            <label className="no-date-label">Start Date</label>
            <DateInputField
              className="inline date-field"
              value={startDate}
              onDateChange={this._onChangeStartDate}
              onMonthChange={this._onChangeStartDate}
              maxDate={maxStartDate}
            />
          </div>
          <div className="book-of-business-container">
            <label className="no-date-label">End Date</label>
            <DateInputField
              className="inline date-field"
              value={endDate}
              onDateChange={this._onChangeEndDate}
              onMonthChange={this._onChangeEndDate}
              minDate={minEndDate}
              maxDate={maxEndDate}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="content-container">{this._requestReportButton()}</div>
        {this._renderExportButton()}
        {this.renderDescription()}
        {this.renderCalendars()}
      </div>
    );
  }
}

BookOfBusiness.propTypes = {
  customers: PropTypes.array.isRequired,
  selectedCustomer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  report: PropTypes.string,
  resetReport: PropTypes.func.isRequired,
  requestCustomers: PropTypes.func.isRequired,
  requestBookOfBusiness: PropTypes.func.isRequired,
  updateStartDate: PropTypes.func.isRequired,
  updateEndDate: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
};

BookOfBusiness.defaultProps = {
  selectedCustomer: null,
  startDate: null,
  endDate: null,
  report: null,
};

export default BookOfBusiness;
